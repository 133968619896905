<template>
  <div class="braces-reissue">
    <div class="header-title">
      <div class="done-case">
        {{ $t("braceReissue.jjyt") }}
        <!--        {{ caseDetails.caseNumber }}-->
      </div>
      <div class="clinic-name">
        {{ $t("cases.maintain.zsmc") }}: {{ caseDetails.clinicName }}
      </div>
      <div class="product-name">
        {{ $t("cases.maintain.cpmc") }}: {{ caseDetails.productName }}
      </div>
    </div>
    <div v-if="isLoading" class="loading-content">
      <img
        class="loading-icon"
        src="../../../../../components/loading/loading.gif"
        alt=""
      />
    </div>
    <div class="content" v-else>
      <div class="option" :style="{ top: isShowNews ? '1.0rem' : '0.68rem' }">
        <div class="tabs">
          <div
            :class="['tabs-item', nowTabIndex === index && 'active-tabs-item']"
            :style="{ width: lang === 'zh_CN' ? '1.18rem' : '2.5rem' }"
            v-for="(item, index) in tabs"
            :key="index"
            @click="changeTabIndex(index)"
          >
            <img
              class="tabs-icon"
              :src="`/static/images/maintain/${item.icon}.png`"
              alt=""
            />
            {{ item.name }}
          </div>
        </div>
        <!-- 按钮监听做禁用判断 -->
        <div class="option-control" v-if="nowTabIndex === 0">
          <div
            :class="!disabled ? 'submit' : 'submit-disabled'"
            @click="stagingOrSubmit"
          >
            {{ $t("braceReissue.tjsq") }}
          </div>
        </div>
      </div>
      <BracesReissueTable
        ref="tableData"
        @toExpress="toExpress"
        :aidLogisticsFlag="aidLogisticsFlag"
        :modelType="modelType"
        v-if="nowTabIndex === 0"
        :reissueId="reissueId"
        :caseDetails="caseDetails"
        @showExpress="showExpress"
      />
      <AuditTrail
        v-if="nowTabIndex === 1"
        :reload="reload"
        :caseDetails="caseDetails"
        @doAction="doAction"
      />
    </div>
    <ExBirdie
      :defaultKey="exDefaultKey"
      caseType="1"
      :isReissueFlag="true"
      :caseDetail="caseDetails"
      @orderSuccess="orderSuccess"
      ref="exBirdie"
      refType="2"
      @tabsChange="tabsChanged"
      :canCreate="canCreate"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import BracesReissueTable from "../components/braces-reissue-table.vue";
import AuditTrail from "../components/audit-trail.vue";
import ExBirdie from "components/expressage_birdie/index";
import {
  createBracesReissue,
  createUnSubmit,
  bracesReissueDetail,
  submitAndTipsFlag
} from "common/api/cases";
import { extend, pickBy } from "lodash";
import { notifyMsg } from "common/js/util";

export default {
  data() {
    return {
      lang: localStorage.getItem("user_lang") || "zh_CN",
      caseId: "",
      curePlanId: "",
      isLoading: true,
      caseDetails: {},
      tabs: [
        {
          name: this.$t("braceReissue.ytsq"),
          idx: 0,
          icon: "complete"
        },
        {
          name: this.$t("braceReissue.shjl"),
          idx: 1,
          icon: "preview"
        }
      ],
      nowTabIndex: 0,
      reissueId: "",
      orderType: "",
      disabled: false,
      exDefaultKey: "",
      reload: false,
      aidLogisticsFlag: "",
      modelType: "",
      canCreate: false
    };
  },
  components: {
    BracesReissueTable,
    AuditTrail,
    ExBirdie
  },
  computed: {
    ...mapState({
      isShowNews: state => state.isShowNews,
      language: state => state.language
    }),
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    }
  },
  methods: {
    ...mapActions({
      getCommonCaseDetail: "actionGetCommonCaseDetail" //病例详情
    }),
    toExpress() {
      this.changeQuery({ isLog: true });
      this.nowTabIndex = 1;
    },
    tabsChanged(key) {
      this.modelType = key;
    },
    showExpress(key, canCreate = false) {
      this.exDefaultKey = key;
      this.$refs["exBirdie"].openDia().then(() => {
        this.$refs["exBirdie"] &&
          this.$refs["exBirdie"].resetClinic(this.caseDetails.clinicId);
      });
      this.canCreate = canCreate;
    },
    async doAction(val) {
      this.reissueId = val.id;
      this.changeQuery({ reissueId: val.id, isLog: true });
      const reissueDetail = await bracesReissueDetail(val.id);
      if (val.status === "0") {
        if (val.isSubmitModel === "1" && val.isCompleteLogistics === "0") {
          if ("modelLogisticsId" in val) {
            sessionStorage.setItem("logisticsId", val.modelLogisticsId);
          }
          this.showExpress(reissueDetail["submitModelType"]);
        }
      } else {
        this.nowTabIndex = 0;
      }
    },
    orderSuccess() {
      this.reload = true;
      this.aidLogisticsFlag = "0";
    },
    stagingOrSubmit() {
      const data = this.$refs.tableData.stagingData();
      const {
        receiveType,
        isSubmitModel,
        submitModelType,
        phone,
        phoneArea,
        provinces,
        attachmentInfoList,
        receiveUser,
        receiveAddressId,
        contactWay,
        address
      } = data;
      if (this.disabled) {
        return;
      }
      if (
        data["toothUpJawMaterial"] === "" &&
        data["toothDownJawMaterial"] === ""
      ) {
        return notifyMsg(this, "warning", this.$t("braceReissue.qxzbshzcz"));
      }
      if (receiveType === "1") {
        if (receiveAddressId === "") {
          return notifyMsg(this, "warning", this.$t("braceReissue.qxzzsshdz"));
        }
      } else if (receiveType === "2") {
        if (!receiveUser) {
          return notifyMsg(
            this,
            "warning",
            this.$t("cases.createUser.qtxsjrxm"),
            2000
          );
        }
        if (contactWay == 2) {
          if (!phoneArea) {
            return notifyMsg(this, "warning", this.$t("birdie.qtxqh"));
          }
          if (!phone) {
            return notifyMsg(this, "warning", this.$t("birdie.qtxdhhm"));
          }
          if (phoneArea && phone) {
            const areaPhonePass = /^0\d{2,4}$/.test(phoneArea);
            const phonePass = /^\d{7,8}$/.test(phone);
            if (!areaPhonePass || !phonePass) {
              if (!areaPhonePass) {
                return notifyMsg(this, "warning", this.$t("birdie.qhsryw"));
              }
              if (!phonePass) {
                return notifyMsg(this, "warning", this.$t("birdie.dhhmsryw"));
              }
            }
          }
        } else {
          if (
            !phone ||
            !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
              phone
            )
          ) {
            if (!phone) {
              return notifyMsg(
                this,
                "warning",
                this.$t("cases.createUser.qsrsjrsjhm"),
                2000
              );
            } else if (
              !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
                phone
              )
            ) {
              return notifyMsg(this, "warning", this.$t("birdie.sjrsjhmyw"));
            }
          }
        }
        if (!provinces[0] || !provinces[1] || !provinces[2] || !address) {
          return notifyMsg(
            this,
            "warning",
            this.$t("cases.createUser.qtxssqjdz"),
            2000
          );
        }
      } else if (receiveType === "3") {
        if (receiveAddressId === "") {
          return notifyMsg(this, "warning", $t("yssh.qxzysshdz"));
        }
      }
      if (isSubmitModel === "1") {
        if (submitModelType === "") {
          return notifyMsg(this, "warning", this.$t("braceReissue.qxzgxjmx"));
        } else {
          if (submitModelType === "local_upload") {
            if (attachmentInfoList.length !== 2) {
              return notifyMsg(
                this,
                "warning",
                this.$t("braceReissue.qscszmxwj")
              );
            }
          } else {
            if (
              ["iTero", "other"].includes(submitModelType) &&
              attachmentInfoList.length === 0
            ) {
              return notifyMsg(
                this,
                "warning",
                this.$t("braceReissue.qscszmxwj")
              );
            }
          }
        }
      }
      // 重启次数等于0并且无当前发货信息禁用其余条件下放开
      delete data["provinces"];
      if (data.attachmentInfoList.length === 0) {
        delete data["attachmentInfoList"];
      }
      const _params = {
        toothDownJawMaterial: data?.toothDownJawMaterial || "",
        toothDownJawStep: data?.toothDownJawStep || "",
        toothUpJawMaterial: data?.toothUpJawMaterial || "",
        toothUpJawStep: data?.toothUpJawStep || ""
      };
      const contentList = this.getContentList(_params);
      const batchInfo = {
        productionContentList: contentList
      };
      createBracesReissue({
        caseId: this.caseDetails["id"],
        ...pickBy(data),
        chargeType: "1",
        orderType: this.orderType,
        ..._params,
        batchInfo
      }).then(res => {
        this.$router.replace(
          `/cases/detail?caseId=${this.$route.query.caseId}&curePlanId=${this.$route.query.curePlanId}`
        );
        notifyMsg(this, "success", this.$t("login.retrievePaw.czcg"));
      });
    },
    getContentList(params) {
      const type = ["2", "3"].includes(this.caseDetails.productSystemType)
          ? this.caseDetails.productSystemType
          : "1";
      // 拆分上下颌的材质和步骤
      const downJawMaterials = params.toothDownJawMaterial
          .split(",")
          .filter(Boolean);
      const downJawSteps = params.toothDownJawStep.split(",").filter(Boolean);
      const upJawMaterials = params.toothUpJawMaterial
          .split(",")
          .filter(Boolean);
      const upJawSteps = params.toothUpJawStep.split(",").filter(Boolean);
      // 初始化 productionContentList
      const productionContentList = [];
      // 处理下颌数据
      downJawMaterials.forEach((material, index) => {
        productionContentList.push({
          type: type, // 材质
          overcorrect: "0", // 校正信息（可选）
          position: "2", // 位置
          step: parseInt(downJawSteps[index], 10) || 0, // 步骤
          plasticSheetId: material.trim(), // 塑料片 ID（可选）
          quantity: 1, // 数量（可选）
          orderNo: index // 订单号（可选）
        });
      });

      // 处理上颌数据
      upJawMaterials.forEach((material, index) => {
        productionContentList.push({
          type: type, // 材质
          overcorrect: "0", // 校正信息（可选）
          position: "1", // 位置
          step: parseInt(upJawSteps[index], 10) || 0, // 步骤
          plasticSheetId: material.trim(), // 塑料片 ID（可选）
          quantity: 1, // 数量（可选）
          orderNo: index // 订单号（可选）
        });
      });
      return productionContentList;
    },
    changeTabIndex(idx) {
      this.nowTabIndex = idx;
      submitAndTipsFlag(this.caseId).then(res => {
        this.aidLogisticsFlag = res.aidLogisticsFlag;
        this.changeQuery({
          aidLogisticsFlag: res.aidLogisticsFlag,
          isAllowedSubmit: res.isAllowedSubmit,
          logisticsReissueId:
            "logisticsReissueId" in res ? res.logisticsReissueId : "",
          reissueId: "unSubReissueId" in res ? res.unSubReissueId : ""
        });
      });
      this.changeQuery({
        tabIdx: idx
      });
    },
    changeQuery(arg) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, arg)
      });
    },
    getCaseDetail() {
      this.getCommonCaseDetail(this.caseId).then(data => {
        let orderType = "";
        if (["A", "B", "C", "E", "I"].includes(data["cureType"])) {
          if (["C", "D", "E"].includes(data["stage"])) {
            orderType = "batch"; // 矫治器
          }
        }
        if (data["stage"] === "E") {
          if (data["productSystemType"] === "3") {
            orderType = "retainer"; // 保持器
          } else if (data["productSystemType"] === "2") {
            orderType = "beauty"; // 美学牙套
          }
        }
        if (this.$route.query.reissueId !== "") {
          this.reissueId = this.$route.query.reissueId;
          if (this.$route.query.isLog) {
            this.changeQuery({ reissueId: this.$route.query.reissueId });
          }
        } else {
          if (orderType !== "") {
            createUnSubmit({
              caseId: data["id"],
              orderType
            }).then(res => {
              this.changeQuery({ reissueId: res });
            });
          }
        }
        if (
          (data["curePlanInfo"].nowStepUpjaw === "0" &&
            data["curePlanInfo"].nowStepDownjaw === "0" &&
            data["curePlanInfo"].reopenNumber == 0) ||
          this.$route.query.isAllowedSubmit == "0" ||
          data["nextStatus"] === "abandon" ||
          data["stage"] === "E"
        ) {
          this.disabled = true;
          this.caseDetails = data;
          this.isLoading = false;
          return;
        }
        this.orderType = orderType;
        this.caseDetails = data;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.caseId = this.queryCaseId;
    this.curePlanId = this.queryCurePlanId;
  },
  mounted() {
    submitAndTipsFlag(this.caseId).then(res => {
      this.aidLogisticsFlag = res.aidLogisticsFlag;
      this.changeQuery({
        aidLogisticsFlag: res.aidLogisticsFlag,
        isAllowedSubmit: res.isAllowedSubmit,
        logisticsReissueId:
          "logisticsReissueId" in res ? res.logisticsReissueId : "",
        reissueId: "unSubReissueId" in res ? res.unSubReissueId : ""
      });
      this.getCaseDetail();
    });
    if (this.$route.query.tabIdx) {
      this.nowTabIndex = +this.$route.query.tabIdx;
    } else {
      this.nowTabIndex = 0;
    }
  }
};
</script>

<style scoped lang="scss">
.braces-reissue {
  width: 16rem;
  height: 87.5vh;
  margin: auto;
  position: relative;

  .loading-content {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-top: 100px;

    .loading-icon {
      width: 48px;
      height: 48px;
    }
  }

  .header-title {
    font-weight: 700;
    margin: 30px 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    display: flex;
    align-items: center;

    .clinic-name {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .done-case {
      font-size: 20px;
      line-break: 28px;
      //font-weight: 500;
    }

    > div {
      padding-right: 10px;
      margin-left: 10px;
      position: relative;

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 16px;
        background-color: #333;
        margin-left: 10px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .content {
    min-height: 100%;
    background-color: #fff;
    border-radius: 6px;

    .option {
      height: 72px;
      padding: 0 30px;
      border-bottom: 4px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0.68rem;
      background-color: #fff;
      z-index: 99;

      .tabs {
        height: 100%;
        display: flex;
        align-items: center;

        .tabs-item {
          white-space: nowrap;
          cursor: pointer;
          height: 100%;
          width: 118px;
          margin-right: 80px;
          display: flex;
          align-items: center;
          font-size: 18px;
          line-height: 26px;
          color: #333;
          position: relative;

          .tabs-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        .active-tabs-item {
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 4px;
            background-color: $main_theme_color;
            left: 0;
            bottom: -4px;
          }
        }
      }

      .option-control {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;

        > div {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          padding: 20px;
          min-width: 96px;
          border-radius: 4px;
        }

        .next-page,
        .previous-page,
        .submit,
        .submit-disabled {
          background-color: $main_theme_color;
          margin-right: 10px;
          color: #fff;
        }

        .submit {
          margin-right: 0px;
        }

        .submit-disabled {
          background-color: #f4f4f4;
          cursor: not-allowed;
        }

        .staging {
          border: 1px solid #e5e5e5;
        }
      }
    }
  }
}
</style>
